<template>
  <div class="home">
    <header>
      <m-page-header title="操作指南"></m-page-header>
    </header>
    <main>
      <m-form-skeleton :loading="loading" type="text">
        <div class="description">
          <ul>
            <li class="title">
              <h5>操作指南：</h5>
            </li>

            <li>1.配置前台展示的内容及变量。</li>
            <li>2.根据需要可配置商城前台显示或隐藏内容。</li>
          </ul>
        </div>
        <div class="images">
          <m-image-preview :imgList="[home1, home2]" />
        </div>
        <div class="btn">
          <el-button
            :type="isInstall ? 'danger' : 'primary'"
            size="medium"
            @click="setPlugin"
            :loading="btnLoading"
          >
            {{ isInstall ? '卸载插件' : '安装插件' }}
          </el-button>
        </div>
      </m-form-skeleton>
    </main>
  </div>
</template>

<script>
import { ref } from 'vue';
import MPageHeader from '@/components/pageHeader/PageHeader.vue';
import MImagePreview from '@/components/imagePreview/ImagePreview.vue';
import MFormSkeleton from '@/components/formSkeleton/FormSkeleton';
import { getInstallStatus, install, uninstall } from '../api/home';

import home1 from '../statics/img/home1.png';
import home2 from '../statics/img/home2.png';
import home3 from '../statics/img/home3.png';
import home4 from '../statics/img/home4.png';
import { ElMessage } from 'element-plus';
export default {
  name: 'Home',
  components: {
    'm-page-header': MPageHeader,
    'm-image-preview': MImagePreview,
    MFormSkeleton,
  },
  setup() {
    const loading = ref(true);
    const isInstall = ref(false); // 是否已安装
    const btnLoading = ref(false);

    // 获取安装状态
    const setInstallStatus = async () => {
      const { data, status } = await getInstallStatus(loading);

      if (!status) return;
      isInstall.value = !!data;
    };

    // 卸载 / 安装 插件
    const setPlugin = async () => {
      if (isInstall.value) {
        const res = await uninstall(btnLoading);
        if (!res.status) return;

        ElMessage.success('卸载成功');
        isInstall.value = !isInstall.value;
      } else {
        const res = await install(btnLoading);
        if (!res.status) return;
        ElMessage.success('安装成功');
        isInstall.value = !isInstall.value;
      }
    };
    return {
      home1,
      home2,
      home3,
      home4,
      setInstallStatus,
      setPlugin,
      isInstall,
      btnLoading,
      loading,
    };
  },
  mounted() {
    this.setInstallStatus();
  },
};
</script>

<style lang="scss" scoped>
main {
  padding: 20px;
  .description {
    ul {
      li {
        padding: 8px 0;
        .title {
          padding: 10px 0;
        }
      }
    }
  }
  .images {
    padding: 20px 0;
  }
  .btn {
    padding-bottom: 20px;
  }
}
</style>
