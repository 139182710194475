import request from '@/tools/request';

export const getInstallStatus = loading => {
  return request({
    url: '/detailmarkettool-index-guide.html',
    loading,
  });
};

export const uninstall = loading => {
  return request({
    url: '/detailmarkettool-index-del.html',
    loading,
  });
};
export const install = loading => {
  return request({
    url: '/detailmarkettool-index-set.html',
    loading,
  });
};
